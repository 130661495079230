export const bannerImg = [
  // {
  //   img: 'banner0',
  //   buryPointText: '首页-轮播图-1+IGCSE/ALevel词汇册',
  //   url: '/mentor'
  // },
  // {
  //   img: 'banner',
  //   buryPointText: '首页-轮播图-1+IGCSE/ALevel词汇册',
  //   url: '/mentor'
  // },
  // {
  //   img: 'banner1',
  //   buryPointText: '首页-轮播图-1+IGCSE/ALevel词汇册',
  //   url: 'https://www.boluozaixian.cn/caseDetails?id=15'
  // },
  {
    img: 'banner2',
    buryPointText: '首页-轮播图-2+CIE 爱德思同步辅导寒假预学班',
    url: '/alevel'
  },
  {
    img: 'banner3',
    buryPointText: '首页-轮播图-3+ALevel 爱德思统考圣诞冲刺班',
    url: '/ap'
  },
  {
    img: 'banner4',
    buryPointText: '首页-轮播图-4+CIE 爱德思春招预学寒假班',
    url: '/igcse'
  },
  {
    img: 'banner5',
    url: '/ib',
    buryPointText: '首页-轮播图-5+定制备考方案'
  }
]

export const oneData = [
  {
    url: '/alevel',
    type: 'A-Level课程',
    subjectList: ['数学', '进阶数学', '物理', '化学', '生物', '会计', '经济', '商务', '计算机科学', '历史', '地理', '心理学'],
    navbg: 'Alevel',
    navbg1: 's_Alevel',
    bot: 'bot01',
    content: [
      {
        title: '一对一课程',
        maintitle: '量身定制方案、夯实基础'
      },
      {
        title: '3-6人小班',
        maintitle: '精准匹配同一学力考生，师资强悍，高性价比'
      },
      {
        title: '考前冲刺',
        maintitle: '标准化练习，定期真题模考'
      },
      {
        title: 'A-level全日制',
        maintitle: '集结菠萝在线深厚教学资源，助力提升'
      }
    ]
  },
  {
    url: '/igcse',
    type: 'IGCSE课程',
    subjectList: ['数学', '物理', '化学', '商务', '生物', '计算机', '经济', '历史', '地理', '心理学'],
    navbg: 'IGCSE',
    navbg1: 's_IGCSE',
    bot: 'bot02',
    content: [
      {
        title: '同步培优课程',
        oneTitle: '课程内容：',
        oneData: '课外辅导，解决学习疑惑，梳理知识重点和难点，帮助学生形成健全的学术知识体系。课程紧贴日常的国际学校学习进度，是学生学术不惜的强力支持',
        twoTitle: '课程安排：',
        twoData: '11-3月滚动开班，节假日和周六/周日授课'
      },
      {
        title: '备考冲刺课程',
        oneTitle: '课程内容：',
        oneData: '考试季前帮助学生有效梳理考试重点和盲点，查漏补缺，结合历年考点和最新的考试政策，并结合真题练习与解析，帮助学生充分准备考试',
        twoTitle: '课程安排：',
        twoData: '8-10月，12-1月（寒假集训），3-5月，节假日和周六/周日授课'
      },
      {
        title: '暑假预习课程',
        oneTitle: '课程内容：',
        oneData: '帮助学生补习以往学习过程中的遗漏点，同时对更高年级课程进行预习，提前做好扎实的学术知识储备，并进一步提升学术能力，取得学习的先发优势，赢在起跑线上',
        twoTitle: '课程安排：',
        twoData: '暑假（7-8月）'
      }
    ]
  },
  {
    url: '/ib',
    type: 'IB课程',
    subjectList: ['中文文学A', '英文B', '经济学', '物理', '数学', '化学', '数学学习', '历史', 'TOK+物理EE', '英文文学A', 'TOK+经济学EE', '英文语言和文学A', '社会和文化人文学', '环境系统和社会', 'TOK+社会和文化人文学EE'],
    navbg: 'IB',
    navbg1: 's_IB',
    bot: 'bot03',
    content: [
      {
        title: '同步培优课程',
        oneTitle: '课程内容：',
        oneData: '课外辅导，解决学习疑惑，梳理知识重点和难点，帮助学生形成健全的学术知识体系。课程紧贴日常的国际学校学习进度，是学生学术不惜的强力支持',
        twoTitle: '课程安排：',
        twoData: '8-4线上月滚动开班，节假日和周六/周日课'
      },
      {
        title: '备考冲刺课程',
        oneTitle: '课程内容：',
        oneData: '考试季前帮助学生有效梳理考试重点和盲点，查漏补缺，结合历年考点和最新的考试政策，并结合真题练习与解析，帮助学生充分准备考试',
        twoTitle: '课程安排：',
        twoData: '2-5月，节假日和周六/周日课'
      },
      {
        title: 'IA/EE/TOK辅导课程',
        oneTitle: '课程内容：',
        oneData: '针对IB学生需要大量的论文写作及做presentation，解决学生研究没思路，形式不规范等问题，提供专业的学术写作培训课程，并根据相应做IA/EE指导',
        twoTitle: '课程安排：',
        twoData: 'TOK Oral（7-8月），2-5月线上滚动开班，TOK Essay：9-1月，线上线下滚动开班'
      }
    ]
  },
  {
    url: '/ap',
    type: 'AP课程',
    subjectList: ['微积分', '统计', '化学', '计算机A', '美国历史', '欧洲历史', '环境科学', '人文地理', '宏观微经济', '心理学', '物理1&2&C', '英文语言写作', '英文文学写作', '美国政府与政治'],
    navbg: 'AP',
    navbg1: 's_AP',
    bot: 'bot04',
    content: [
      {
        title: 'GPA护航课程',
        oneTitle: '课程内容：',
        oneData: '根据各高校不同课程体系及规划，量身定制符合该高校学生进度的同步课程辅导，解决学生疑惑，梳理知识重点和难点，是学生在校GPA的强力支持',
      },
      {
        title: '备考冲刺课程',
        oneTitle: '课程安排：',
        oneData: '1月底-2月中寒假集中授课，3、4月周末冲刺刷题',
      },
      {
        title: '暑假预习课程',
        oneTitle: '课程内容：',
        oneData: '利用学生暑假时间，回顾前一年内容，查漏补缺。针对下学年课程进行预习，扎实学习基础，提升学术能力，取得学习上的先发优势，赢在起跑线上',
      }
    ]
  },
  {
    url: '/mentor',
    type: '国际学校备考',
    subjectList: [],
    navbg: 'beikao',
    navbg1: 's_beikao',
    bot: 'bot05',
    content: [
      {
        title: '名校直通车课程',
        oneTitle: '阅读与词汇；语法与写作；听力与口语；中英文数学',
      },
      {
        title: '专项定制课程',
        oneTitle: '根据学生需求查漏补缺，短期内帮助学生高校提升，阅读与词汇；语法与写作；听力与口语；中英文数学物理化学',
      },
      {
        title: '基础预备课程',
        oneTitle: '做全面准备、扎实应对各类国际学校入学测试',
      }
    ]
  },
  {
    url: '/toefl-course',
    type: '托福',
    subjectList: [],
    navbg: 'tuofu',
    navbg1: 's_tuofu',
    bot: 'bot06',
    content: [
      {
        title: '托福预备班(40-65分)',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '托福基础提高班（60-95分）',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '托福高分突破班（90-110分）',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '托福真题冲刺班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '托福95分封闭集训班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '托福105分封闭集训班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '托福一对一',
        oneData: '1V1',
        twoData: '根据学员需求，私人定制'
      }
    ]
  },
  {
    url: '/ielts',
    type: '雅思',
    subjectList: [],
    navbg: 'yasi',
    navbg1: 's_yasi',
    bot: 'bot07',
    content: [
      {
        title: '5-6.5基础分提高班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '雅思真题冲刺班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '雅思6分封闭集训班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '6-7.5分高分突破班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '雅思备考陪练班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '雅思7分封闭集训班',
        oneData: '1V4',
        twoData: '滚动开班'
      },
      {
        title: '雅思一对一',
        oneData: '1V1',
        twoData: '根据学员需求，私人定制'
      }
    ]
  },
  {
    type: '国际竞赛',
    subjectList: ['STEP', 'NEC', 'GAUSS', 'UKCHO', 'EUCLID', 'MAT', 'USABO', 'PAT', 'USACO', 'BPHO', 'USNCO', 'AMC8/10/12', 'BMO', 'PHYSICS BOWL'],
    navbg: 'jingsai',
    navbg1: 's_jingsai',
    bot: 'bot08',
    content: [
      {
        title: '一对一课程',
        maintitle: '匹配提升方案、夯实基础'
      },
      {
        title: '自组小班',
        maintitle: '精准匹配同一学力考生，师资强悍，高性价比'
      }

    ]
  }
]

export const twoData = [
  {
    num: 'no1',
    bg: 'no1bg',
    img: 'no1img',
    out: 'no1out',
    txt1: '准备考',
    txt2: '国际学校',
    txt3: '准备考国际学校',
    txt4: '升学路径规划',
    txt5: '专属备考方案',
    txt6: '择校一手资讯'
  },
  {
    num: 'no2',
    bg: 'no2bg',
    img: 'no2img',
    out: 'no2out',
    txt1: '顺利考入',
    txt2: '国际学校',
    txt3: '顺利考入国际学校',
    txt4: '国际学校衔接课程',
    txt5: '同步辅导课程',
    txt6: '学科高分冲刺'
  },
  {
    num: 'no3',
    bg: 'no3bg',
    img: 'no3img',
    out: 'no3out',
    txt1: '准备冲刺',
    txt2: 'G5&藤校',
    txt3: '准备冲刺G5&藤校',
    txt4: '托福雅思',
    txt5: '国际竞赛',
    txt6: '背景提升'
  }
]

export const threeData = [
  {
    img: 'shangshijq',
    name: '上实剑桥',
    number: '349'
  },
  {
    img: 'guanghuajq',
    name: '光华剑桥',
    number: '253'
  },
  {
    img: 'lingke',
    name: '领科教育',
    number: '245'
  },
  {
    img: 'jiaoda',
    name: '交大A-Level',
    number: '127'
  },
  {
    img: 'pinghesy',
    name: '平和双语',
    number: '94'
  },
  {
    img: 'qibaodht',
    name: '七宝德怀特',
    number: '80'
  },
  {
    img: 'deyingle',
    name: '德英乐',
    number: '53'
  },
  {
    img: 'shiwai',
    name: '世界外国语',
    number: '31'
  },
]
export const offerData = [
  ['offer01', 'offer02', 'offer03', 'offer04'],
  ['offer05', 'offer06', 'offer07', 'offer08'],
  ['offer09', 'offer10', 'offer11', 'offer12'],
  ['offer13', 'offer14', 'offer15', 'offer16']
]

export const fourData = [
  {
    img: 'teacher1',
    name: '沈卓航 Thomas',
    school: '伦敦大学 学院荣誉学士',
    details: [
      '连续2年获得UK SMC英国数学竞赛全校最佳',
      'A Level考试亲历者，渠道A*A*A*A的优秀成绩，雅思7.5',
    ],
    result: '毕业后全职进入国际教育领域，在一线从事教学和教研工作，精通IB、A Level、AP数学课程'
  },
  {
    img: 'teacher2',
    name: '李老师 Iris',
    school: '墨尔本大学化学工程专业硕士',
    details: [
      '墨尔本大学化学工程专业本硕连',
      '曾担任过化学及化学工程课程助教',
    ],
    result: '擅于把握学生学习心理，通过富有感染力的课堂教学，调动学生学习积极性化，所辅导的备考学生以优秀成绩进入领科、WLSA、尚德等国际学校。'
  },
  {
    img: 'teacher3',
    name: '钱老师 Eunice',
    school: '伦敦城市大学硕士',
    details: [
      'GCSE / A Level亲历者，数学、高数、经济三门取到A*A*A*的优秀成绩',
    ],
    result: '10年英式教育受益者，熟悉英国风土人情、学术现状，擅长把复杂问题简单化，引导学生理解枯燥繁琐的数学公式，通过举一反三将知识点串联起来，意识到数学的有趣之处。'
  },
  {
    img: 'teacher4',
    name: '曹老师 Janette',
    school: '英国兰卡斯特大学金融硕士',
    details: [
      '大四被派往英国格拉斯哥大学交换学习',
      '金融第一证”CFA二级',
    ],
    result: '擅长将理论结合实际，对社会和国家的经济现象进行经济的分析与教学，曾参与“IGCSE/A Level经济词汇册”的编写，参与经济课程的研发工作。'
  },
  {
    img: 'teacher5',
    name: '田老师 Emily',
    school: '伦敦大学学院语言学专业硕士',
    details: [
      '连续3年获得国家级奖学金获得者',
      '持有剑桥大学TKT证书',
      '雅思听力、阅读8.5的高分',
    ],
    result: '主攻雅思、托福、Duolingo 、国际学校备考英语课程，辅导备考过程中多次押中原题。'
  },
  {
    img: 'teacher6',
    name: '朱老师 Lydia',
    school: '英国华威大学荣誉硕士',
    details: [
      'A-Level成绩A*A*A，雅思8分，其中听力9分',
      '7年留学英国经历（高中到硕士），纯正英式口音',
    ],
    result: '物理一线教研开发负责人，参与编写IGCSE/A Level物理词汇册，所辅导众多学生在A Level统考中获得物理优秀成绩。'
  },
  {
    img: 'teacher7',
    name: '许老师 joey',
    school: '华威大学经济学荣誉学士',
    details: [
      '7年欧洲留学工作经验',
      '英语口语发音纯正，接近Native Speaker水准',
    ],
    result: '善于挖掘学员学习英语的兴趣，促进学员英语能力有效提升，所辅导的标化语言有效提升学员成绩。'
  },
  {
    img: 'teacher8',
    name: '黄老师 Bob',
    school: '都柏林大学学院硕士',
    details: [
      '雅思8分，英语水平接近Native Speaker',
      '大学期间主导为院校建立语言中心',
    ],
    result: '擅于将英语知识从学生兴趣角度切入，让学生快速喜欢上英语听力口语，帮助众多学生取得优秀成绩。'
  },
  {
    img: 'teacher9',
    name: '张老师 Tracy ',
    school: '帝国理工学院硕士学位',
    details: [
      'A Level数学、进阶数学、物理三门A*A*A*',
      '帝国理工大学连续两年获得优秀学生奖',
    ],
    result: '擅长IB、IGCSE、A Level、美高+AP、SAT II的物理课程，辅导学生考取理想成绩。'
  }
]

//修改前的显示数据
// export const fiveData = [
//   {
//     img: 'neclogo',
//     txt1: 'NEC 2021官方授权考点',
//     txt2: '全美经济学挑战赛 (National Economics Challenge)，是美国最具影响力的高中生经济学竞赛。由美国经济教育学会 (Council for Economic Education) 举办。每年影响超过 11,000 名高中学生，超过 20%的 NEC 美国决赛选手被哈佛、耶鲁、斯坦福等藤校录取。'
//   },
//   {
//     img: 'ucaslogo',
//     txt1: 'UCAS 2021官方授权管理中心',
//     txt2: 'UCAS 是 Universities and Colleges Admissions Service 的缩写，即“大学和学院招生服务中心”，UCAS统一为英国所有大学提供招生服务，申请英国大学的本科学位课程，都要通过UCAS进行申请。'
//   }
// ]

export const fiveData = [
  {
    img:'honor1',
    text:'AQA考试局的正式授权'
  },
  {
    img:'honor2',
    text:'2021年度用户满意教育机构'
  },
  {
    img:'honor3',
    text:'2018年度影响力品牌'
  },
  {
    img:'honor4',
    text:'其他荣誉资质'
  }
]