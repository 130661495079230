// const srcUserId=sessionStorage.getItem('srcUserId')
let host = window.location.host;
let userId;
if(host.substring(0,3) == 'www'){
    // www域名是赵天赐的页面
    userId= '7529'
}else{
    // edu域名是徐恒豪的页面
    userId= '7528'
}
const apis = {
    submitForm:userId ?`/activityList/save?srcUserId=${userId}`:'/activityList/save'
}

export const getApi = key => apis[key];